import { ElMessage } from 'element-plus'

// 时间戳转日期
export function formatDate(now) {
  var date = new Date(now)
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' '
  var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ':'
  var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ':'
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
}

export function dateTimeStr(str = "y-m-d h:i:s", day){
	var date = new Date(day),
	year = date.getFullYear(), //年
	month = date.getMonth() + 1, //月
	day = date.getDate(), //日
	hour = date.getHours(), //时
	minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(), //分
	second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); //秒
	month >= 1 && month <= 9 ? (month = "0" + month) : "";
	day >= 0 && day <= 9 ? (day = "0" + day) : "";
	hour >= 0 && hour <= 9 ? (hour = "0" + hour) : "";
	
	if(str.indexOf('y') != -1){
		str = str.replace('y', year)
	}
	if(str.indexOf('m') != -1){
		str = str.replace('m', month)
	}
	if(str.indexOf('d') != -1){
		str = str.replace('d', day)
	}
	if(str.indexOf('h') != -1){
		str = str.replace('h', hour)
	}
	if(str.indexOf('i') != -1){
		str = str.replace('i', minute)
	}
	if(str.indexOf('s') != -1){
		str = str.replace('s', second)
	}
	return str;
}

// 比较两个时间大小
export function compareDate(date1, date2) {
	let str1 = new Date(date1.replace(/-/g, "/")).getTime()
	let str2 = new Date(date2.replace(/-/g, "/")).getTime()
	return str2 - str1 >= 0
}

// 获取当前日期前几天的日期
export function getOldDay(day) {
  var  dd =  new  Date();
  dd.setDate(dd.getDate()-day); //获取AddDayCount天后的日期
  return formatDate(new Date(dd))
}

// 循环数组的笛卡尔积
export function cartesianProductOf () {
  return Array.prototype.reduce.call(arguments, (a, b) => {
    const ret = []
    a.forEach(function (a) {
        b.forEach(function (b) {
            ret.push(a.concat([b]))
        })
    })
    return ret
  }, [[]])
}

/**
 * @description 复制
 * @param {*} id DOM ID
 */
export function copyDomText (id) {
  const node = document.getElementById(id)
  if (node) {
    let createRange = document.createRange()
    createRange.selectNodeContents(document.getElementById(id))
    const selection = document.getSelection()
    selection.removeAllRanges()
    selection.addRange(createRange)
    document.execCommand('Copy')
    selection.removeAllRanges()
    alert('已复制')
  }
}

export function arrayOperations(arr, newIndex, oldIndex) {
  arr[newIndex] = arr.splice(oldIndex, 1, arr[newIndex])[0]
  return arr
}

export function variableType(val) {
  const valEnum = {
    '[object String]': 'String',
    '[object Number]': 'Number',
    '[object Array]': 'Array',
    '[object Object]': 'Object',
    '[object Null]': 'Null',
    '[object Undefined]': 'Undefined',
    '[object Boolean]': 'Boolean',
    '[object Function]': 'Function',
  }
  return valEnum[Object.prototype.toString.call(val)]
}

export function clearForm(data) {
  console.log('data========', data)
  if (variableType(data) !== 'Object') {
    console.log('data必须要是对象')
    return
  }
  Object.keys(data).forEach((item) => {
    if (variableType(data[item]) === 'String') {
      data[item] = ''
    }
    if (variableType(data[item]) === 'Null') {
      data[item] = null
    }
    if (variableType(data[item]) === 'Boolean') {
      data[item] = false
    }
    if (variableType(data[item]) === 'Undefined') {
      data[item] = undefined
    }
    if (variableType(data[item]) === 'Number') {
      data[item] = 0
    }
    if (variableType(data[item]) === 'Object') {
      console.log('是对象')
      clearForm(data[item])
    }
    if (variableType(data[item]) === 'Array') {
      data[item] = []
    }
    return item
  })
  return data
}

/**
 * @description: 时间段转时间戳
 * @param {*} t
 * @return {*}
 */
export function getTargetTime(t) {
  var d = t.split(' ')[0],
    h = t.split(' ')[1],
    date = new Date()
  date.setYear(d.split('-')[0])
  date.setMonth(d.split('-')[1] - 1)
  date.setDate(d.split('-')[2])
  date.setHours(h.split(':')[0])
  date.setMinutes(h.split(':')[1])
  date.setSeconds(h.split(':')[2])
  return date.getTime()
}

// 时间戳转时间段
export function formatDate1(item) {
  var date = new Date(item)
  var YY = date.getFullYear() + '-'
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return YY + MM + DD + ' ' + hh + mm + ss
}


// 复制内容
export function copyContent(value) {
  var input = document.createElement('input')
  // 把文字放进input中，供复制
  input.value = value
  document.body.appendChild(input)
  // 选中创建的input
  input.select()
  var copy_result = document.execCommand('copy')
  if (copy_result) {
    ElMessage({ type: 'success', message: '复制成功' })
  } else {
    ElMessage({ type: 'error', message: '复制失败' })
  }
  document.body.removeChild(input)
}

// 判断标题是否有特殊字符（表情）
export function isEmojiCharacter(substring){
  for  (var  i = 0; i < substring.length; i ++){
    var  hs = substring.charCodeAt(i);
    if  (0xd800 <= hs && hs <= 0xdbff){
        if  (substring.length> 1){
            var  ls = substring.charCodeAt(i + 1);
            var  uc =((hs - 0xd800)* 0x400)+(ls - 0xdc00)+ 0x10000;
            if  (0x1d000 <= uc && uc <= 0x1f77f){
                return true;
            }
        }
    }  else if (substring.length> 1){
        var  ls = substring.charCodeAt(i + 1);
        if  (ls == 0x20e3){
            return true ;
        }
    }  else  {
        if (0x2100 <= hs && hs <= 0x27ff){
            return true ;
        }  else if  (0x2B05 <= hs && hs <= 0x2b07){
            return true ;
        }  else if  (0x2934 <= hs && hs <= 0x2935){
            return true ;
        }  else if  (0x3297 <= hs && hs <= 0x3299){
            return true ;
        }  else if  (hs == 0xa9 || hs == 0xae || hs == 0x303d || hs == 0x3030
                || hs == 0x2b55 || hs == 0x2b1c || hs == 0x2b1b
                || hs == 0x2b50){
            return true ;
        }
    }
  }
}

export function dateFormatTxt(time) {
  const delta = (new Date().getTime() - new Date(time).getTime()) / 1000
  if (delta / (60 * 60 * 24 * 365) > 1)
    return `${parseInt(delta / (60 * 60 * 24 * 365))}年前`
  if (delta / (60 * 60 * 24 * 30) > 1)
    return `${parseInt(delta / (60 * 60 * 24 * 30))}个月前`
  if (delta / (60 * 60 * 24 * 7) > 1)
    return `${parseInt(delta / (60 * 60 * 24 * 7))}周前`
  if (delta / (60 * 60 * 24) > 1)
    return `${parseInt(delta / (60 * 60 * 24))}天前`
  if (delta / (60 * 60) > 1) return `${parseInt(delta / (60 * 60))}小时前`
  if (delta / 60 > 1) return `${parseInt(delta / 60)}分钟前`
  return '刚刚'
}

export function parseErrors(errors) {
  if(errors && Object.keys(errors).length !== 0) {
    for (let key in errors) {
      if (errors.hasOwnProperty(key)) {
        errors[key].forEach((it) => {
          ElMessage({ message: it, type: 'info', customClass: 'vanMsg' })
        })
      }
    }
  }
}
