<template>
  <div class="pageBox">
    <!-- <div class="searchBox">
      <div class="row">
        <span class="span">商品名称：</span>
        <div class="right"><el-input v-model="goods_name" class="wid100" clearable></el-input></div>
      </div>
      <div class="row">
        <el-button type="primary" @click="handleSearch"><el-icon><Search /></el-icon>&nbsp;筛选</el-button>
      </div>
    </div> -->

    <el-card shadow="never">
      <div class="opaBox">
        <el-button type="primary" @click="handleAdd"><el-icon><Plus /></el-icon>&nbsp;新增</el-button>
        <!-- <el-button type="warning" @click="handleExport"><span class="iconfont icon-daochu"></span>&nbsp;导出</el-button> -->
      </div>
      <el-table :data="goodsList" style="width: 100%" border v-loading="loading">
        <el-table-column prop="id" label="ID" width="80" align="center" />
        <el-table-column prop="name" label="品牌名称" align="center" />
        <el-table-column prop="type" label="品牌类型" align="center">
          <template #default="scope">
            <span>{{ brandType[scope.row.type] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="品牌状态" align="center">
          <template #default="scope">
            <span>{{ scope.row.status ? '启用' : '不启用' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="admin_user.username" label="添加人" align="center" />
        <el-table-column prop="note" label="备注" align="center" />
        <el-table-column prop="sort" label="排序" align="center" />
        <el-table-column label="操作" align="center" width="150">
          <template #default="scope">
            <el-button type="primary" circle @click="handleEdit(scope.row)"><el-icon><Edit /></el-icon></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-pagination">
        <el-pagination
          background
          :current-page="page"
          layout="prev, pager, next, sizes, total"
          :total="total"
          :page-sizes="[10, 50, 100]"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"></el-pagination>
      </div>
    </el-card>

    <el-dialog v-model="showDialog" width="900px" :title="opaType == 'add' ? '新增' : '编辑' ">
      <el-form label-position="right" label-width="110px">
        <el-form-item label="品牌名称：">
          <el-input v-model="itemInfo.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="品牌类型：">
          <el-radio-group v-model="itemInfo.type">
            <el-radio label="self">自有</el-radio>
            <el-radio label="custom">定制</el-radio>
            <el-radio label="default">默认</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="品牌状态：">
          <el-radio-group v-model="itemInfo.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">不启用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="itemInfo.note" type="textarea" :rows="4"></el-input>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input-number v-model="itemInfo.sort" :min="0" />
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showDialog = false">取消</el-button>
          <el-button type="primary" @click="commitOpa()" :loading="opa_loading">确定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue"
import { get, post } from "@/api/request"
import { Search, Plus, Edit, ZoomIn, Delete } from '@element-plus/icons'
import { ElMessage } from 'element-plus'
import { parseErrors } from 'components/common'

export default {
  components: {
    Search, Plus, Edit, ZoomIn, Delete
  },
  setup() {
    const data = reactive({
      goodsList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      pickerTime: [],
      opaType: '',
      showDialog: false,
      opa_loading: false,
      brandType: {
        'self': '自有',
        'custom': '定制',
        'default': '默认'
      },
      itemInfo: {}
    })

    function handleSearch() {
      data.page = 1
      fetchData()
    }

    const fetchData = () => {
      data.loading = true
      let params = {
        page: data.page,
        pageSize: data.pageSize,
        service_id: data.service_id,
        start_date: data.pickerTime ? data.pickerTime[0] : '',
        end_date: data.pickerTime ? data.pickerTime[1] : ''
      }
      get(`/api/brands`, params).then((res) => {
        data.goodsList = res.data
        data.total = res.meta.total
        data.loading = false
      }).catch(() => {
        data.loading = false
      })
    }

    function handleCurrentChange(e) {
      data.page = e
      fetchData()
    }

    function handleSizeChange(e) {
      data.page = 1
      data.pageSize = e
      fetchData()
    }

    function handleAdd() {
      data.opaType = 'add'
      data.itemInfo = {
        status: 1,
        sort: 0
      }
      data.showDialog = true
    }

    function handleEdit(item) {
      data.opaType = 'edit'
      data.itemInfo = JSON.parse(JSON.stringify(item))
      data.showDialog = true
    }
    
    function commitOpa() {
      data.opa_loading = true
      let params = {
        ...data.itemInfo
      }
      if(data.opaType == 'add') {
        post(`/api/brands`, params).then(() => {
          data.page = 1
          fetchData()
          ElMessage({ type: 'success', message: '新增成功' })
          data.showDialog = false
          data.opa_loading = false
        }).catch((err) => {
          data.opa_loading = false
          parseErrors(err.response.data.errors)
        })
      } else {
        post(`/api/brands/${data.itemInfo.id}`, params, 'PUT').then(() => {
          fetchData()
          ElMessage({ type: 'success', message: '编辑成功' })
          data.showDialog = false
          data.opa_loading = false
        }).catch((err) => {
          data.opa_loading = false
          parseErrors(err.response.data.errors)
        })
      }
    }

    onMounted(() => {
      fetchData()
    })

    return {
      ...toRefs(data),
      handleSearch,
      handleCurrentChange,
      handleSizeChange,
      fetchData,
      handleAdd,
      handleEdit,
      commitOpa,

    }
  }
}
</script>

<style lang="scss" scoped>
  .searchBox{
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 15px 0 0 0;
    border-radius: 4px;
    margin-bottom: 15px;
    .row{
      display: flex;
      align-items: center;
      width: 20%;
      box-sizing: border-box;
      margin-bottom: 15px;
      &.row1{
        width: 25%;
      }
      .span{
        display: block;
        width: 80px;
        font-size: 14px;
        text-align: right;
        box-sizing: border-box;
      }
      .right{
        width: calc(100% - 100px);
      }
      .wid100{
        width: 100%;
      }
    }
  }
  .opaBox{
    margin-bottom: 15px;
  }
  .imgBox{
    display: flex;
    flex-wrap: wrap;
    .el-image{
      width: 60px;
      height: 60px;
      border-radius: 4px;
      margin-right: 10px;
    }
  }
  .skuBox{
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 15px 0;
    margin-bottom: 15px;
    background-color: #f3f3f3;
    .tit{
      padding-left: 40px;
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 15px;
    }
  }
</style>
